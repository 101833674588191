










import {Vue, Component, Watch, Emit, Ref} from "vue-property-decorator";
import AddressBookSearchType from "./indexType";
import Search from "@/components/ShareComponent/Search.vue";


@Component({name: "AddressBookSearch",components:{ Search }})
export default class AddressBookSearch extends Vue implements AddressBookSearchType{
    @Ref("searchFriendInput")
    InputObj:any

    show = false
    value = ""

    handleChangeShow(){
        this.show = true
        let time = setTimeout(()=>{
            this.InputObj.focus()
            clearTimeout(time)
        })
    }

    handleBlur(){
        if ( this.value.length < 1 ) this.show = false;
    }

    @Emit("pullAndChangeValue")
    @Watch("value")
    pullAndChangeValue(newVal:string){
        return newVal
    }
}
