




















import {Vue, Component, Prop} from "vue-property-decorator";
import AddressBookListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import AddressBookItem from "@/views/Friend/AddressBook/components/Item/index.vue";

@Component({name: "AddressBookList",components:{ PullDownUpList,AddressBookItem }})
export default class AddressBookList extends Vue implements AddressBookListType{

    handleUpDown(){
        //
    }

    @Prop(Array)
    list!:any[]
    get getList(){ return this.list || [] }

    @Prop(Object)
    listData!:any
    get getListData(){ return this.listData || {} }

}
