






















import {Vue, Component, Prop} from "vue-property-decorator";
import AddressBookItemType from "./indexType";

@Component({name: "AddressBookItem"})
export default class AddressBookItem extends Vue implements AddressBookItemType{

    handleToPath(){
        this.$router.push({
            name:"addFriend",
            query:{
                id:this.getData.id || ''
            }
        })
    }

    get getPhoto(){
        return this.getData && this.getData.headImg || require("@/assets/icon/Basics/default.png")
    }

    @Prop(Object)
    data!:any
    get getData(){
        return this.data
    }

    @Prop(Number)
    index!:number
    get getIndex(){
        return this.index
    }
}
