











import {Vue, Component} from "vue-property-decorator";
import AddressBookType from "./AddressBook";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import AddressBookSearch from "@/views/Friend/AddressBook/components/Search/index.vue";
import AddressBookList from "@/views/Friend/AddressBook/components/List/index.vue";
import { filterList, filterSearch } from './util';
import { testBook } from "@/views/Friend/AddressBook/model";
import {MyFriendsStore} from "@/views/Friend/MyFriends/Store";
import {getFriends} from "@/views/Friend/AddressBook/Server";
import Storage from  "@/util/Storage"

@Component({name: "AddressBook",components:{ HeadTop,AddressBookSearch,AddressBookList }})
export default class AddressBook extends ZoomPage implements AddressBookType{
    keyList:any[] = []
    keyData:any = {}
    phoneArr = []

    activated(){
        // 展示 缓存数据
        this.keyData = JSON.parse( Storage.GetData_?.("hookData") || "{}" )
        if ( JSON.stringify(this.keyData) !== "{}" ){
            this.keyList = Object.keys(this.keyData)
        }
        // 重新判断 通讯录
        this.initData()
    }

    initData(){
        // 重新判断 通讯录
        let list = MyFriendsStore.getMyFriendsList;
        this.phoneArr = JSON.parse(Storage.GetData?.("phoneHook") || "[]")
        if ( list.length && this.phoneArr.length ){
            filterList(this.phoneArr,list).then(res=>{
                let { keyList,keyData } = res
                this.keyData = keyData
                this.keyList = keyList
                Storage.SetData?.("hookData",JSON.stringify(keyData))
                this.loadingShow = false
            });
        }
        this.loadingShow = false
        this.handleUpData()
    }

    handleGetValue(value:string){
        if ( value.length && this.phoneArr.length ){
            let { keyList,keyData } = filterSearch(value, this.phoneArr);
            this.keyList = keyList;
            this.keyData = keyData
        }else{
            this.initData()
        }
    }

    handleUpData(){
        getFriends({ pageNo:1,pageSize:10000 }).then(res=>{
            filterList(this.phoneArr,res).then(res=>{
                let { keyList,keyData } = res
                this.keyData = keyData
                this.keyList = keyList
                Storage.SetData?.("hookData",JSON.stringify(keyData))
            });

            let pageNo = Math.ceil(res.length / 15)
            let upData = MyFriendsStore.getMyFriendsUpData;
            upData.pageNo = pageNo;
            MyFriendsStore.SetMyFriendsUpData(upData)
            MyFriendsStore.SetMyFriendsList(res)
        })
    }


}
